:root {
    --a: 0;
    --card-bg-color: rgb(calc(255 - var(--a) * 255), calc(255 - var(--a) * 255), 255)
}

.publication-card {
    background-color: var(--card-bg-color);
    border: 2px solid rgba(0, 123, 255, 0);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.publication-card:hover {
    transform: scale(1.02);
}

.header {
    border-bottom: 2px solid #007bff;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.work-title {
    font-size: 20px;
    margin: 0;
    color: #007bff;
}

.body {
    margin-top: 10px;
}

.general-data {
    display: flex;
    gap: 5px;
    color: #666;
    margin-bottom: 10px;
}

.date {
    font-weight: bold;
}

.separator {
    font-weight: bold;
}

.type {
    font-style: italic;
}

.external-id {
    margin-bottom: 10px;
}

.id-label {
    color: #007bff;
}

.id-link {
    color: #007bff;
    text-decoration: none;
}

.id-link:hover {
    text-decoration: underline;
}

.contributors {
    margin-bottom: 10px;
    color: #333;
}

.contributors-label {
    font-weight: bold;
}

.abstract-container {
    position: relative;
    margin-bottom: 10px;
}

.abstract-text {
    overflow: hidden;
    max-height: 60px;
    transition: max-height 0.3s ease;
}

.abstract-text.expanded {
    max-height: none;
}

.fade-effect {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--card-bg-color));
}

.toggle-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    padding: 0;
    font-size: 14px;
}

.toggle-button:hover {
    text-decoration: underline;
}

.show-more {
    margin-top: 10px;
}

.show-more-link {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.show-more-link:hover {
    text-decoration: underline;
}

.source {
    margin-top: 20px;
    font-size: 14px;
    color: #666;
}
