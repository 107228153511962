.App {
    font-family: sans-serif;
    text-align: center;
}

.cv-page {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

/*noinspection SpellCheckingInspection*/
.chrono-container {
    margin-top: 40px;
}

.link {
    z-index: 2000;
}
