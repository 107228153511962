:root {
    --crop-heigth: 620px;
    --img-height: 200px;
}

/* Individual event card */
.event-card {
    min-height: calc(var(--crop-heigth) + var(--img-height) + 71px);
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    width: 320px;
    display: flex;
    flex-direction: column;
    text-align: left;
    position: relative;
    height: fit-content;
}

/* Hover effect for event card */
.event-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Event image */
.event-card .event-img {
    height: var(--img-height);
    width: 100%;
    object-fit: cover; /* Ensures the image covers the area */
    object-position: 50% 50%;
    border-bottom: 1px solid #ddd;
    border-radius: 8px;
}


/* Event content */
.event-card .event-content {
    max-height: var(--crop-heigth);
    padding: 15px;
    overflow: hidden;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 95%, rgba(0, 0, 0, 0) 100%);
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 95%, rgba(0, 0, 0, 0) 100%);
}

.event-card .event-content.expanded {
    max-height: none;
    mask-image: none;
    -webkit-mask-image: none;
}

/* Event title */
.event-card .event-title {
    font-size: 1.2em;
    margin: 10px 0;
    color: #333;
}

/* Event date */
.event-card .event-date {
    font-size: 0.9em;
    color: #777;
}

/* Event credits */
.event-card .event-credit-title {
    font-size: 0.95em;
    margin-bottom: -0.5em;
    color: #777;
}

.event-card .event-credit-list {
    list-style-type: none;
    padding: 0;
}

.event-credit-list li, .event-card .event-credit, .event-card .event-caption-title {
    margin-bottom: 5px;
    font-size: 0.85em;
    color: #777;
}

.event-caption-content {
    margin-top: 10px;
    font-size: 0.85em;
    color: #777;
    text-align: center;
}

/* Event location */
.event-card .event-location {
    font-size: 0.9em;
    color: #777;
}

/* Event description */
.event-card .event-description {
    font-size: 1em;
    color: #555;
    margin-top: 10px;

}

.toggle-button {
    margin-top: auto;
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    padding: 10px;
    font-size: 14px;
}

.toggle-button:hover {
    text-decoration: underline;
}

/* Media query for responsive design */
@media (max-width: 776px) {
    .event-card {
        width: 100%;
        margin: 10px 0;
        min-height: 0;
    }
}
