.main-page {
    margin: 0 auto;
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    color: #333;
    max-width: 30cm;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
}

.profile-pic-crop {
    width: 150px; /* Adjust this value to control the circle size */
    height: 150px; /* Make sure the width and height are equal for a perfect circle */
    position: relative;
    overflow: hidden;
    border-radius: 50%; /* Creates the circular crop */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-pic-img {
    position: absolute;
}

.name {
    margin-top: 10px;
    font-size: 24px;
}

.section {
    margin-bottom: 40px;
}

.section-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.section-content {
    line-height: 1.6;
}

.interests-list, .hobbies-list, .contacts-list{
    list-style-type: none;
    padding: 0;
}

.interests-list li, .hobbies-list li {
    margin-bottom: 5px;
}

.content {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 100%;
}

/* Container for two divs */
.side-by-side-container {
    display: flex;
    flex-wrap: wrap; /* Equal width for both divs */
    gap: 20px; /* Adds space between the divs */
}

/* Style for each child div */
.side-by-side-container .side-by-side-item {
    flex: 1 1 45%; /* Allows each item to grow and shrink */
    min-width: min(300px, 100%); /* Minimum width before wrapping occurs */
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

/* Media query for smaller screens */
@media (max-width: 700px) {
    .side-by-side-container .side-by-side-item {
        flex: 1 1 100%; /* Takes full width on smaller screens */
    }
    .side-by-side-container {
         display: flex;
         flex-wrap: wrap; /* Allows wrapping to next line if needed */
     }
}

.box {
    padding: 10px;
}

.links a {
    display: inline-block;
    position: relative;
    padding: 10px 15px;
    font-size: 16px;
    color: #007cff;
    text-decoration: none;
    background-color: transparent;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.links a::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #ff8300;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
}

.links a:hover::after {
    visibility: visible;
    transform: scaleX(1);
}

.links a:hover,
.links a:focus {
    /*background-color: rgba(128, 128, 128, 0.6); !* Grey background with fade-in effect *!*/
    transform: translateY(-2px); /* Slight elevation */
    border-radius: 8px; /* Rounded corners */
}

/* CSS for simple contact information */
.contact-info {
    background-color: #f9f9f9;
    padding: 20px;
    /*border-radius: 8px;*/
    /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);*/
    max-width: 400px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    color: #333;
}

.contact-info h2 {
    margin-top: 0;
    font-size: 24px;
    color: #333;
    text-align: center;
}

.contact-info .info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    font-size: 16px;
    line-height: 1.5;
    white-space: nowrap; /* Prevents line breaks */
}

.contact-info .info-item span {
    display: inline-block;
    flex: 1;
}

.contact-info .info-item span:first-child {
    text-align: left;
}

.contact-info .info-item span:last-child {
    margin-left: 30px;
    text-align: right;
}

.contact-info a {
    color: #007BFF;
    text-decoration: none;
    transition: color 0.3s ease;
}

.contact-info a:hover {
    color: #0056b3;
}


