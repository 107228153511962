.language-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.language {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 80%;
    max-width: 600px;
}

.logo {
    width: 40px;
    height: auto; /* Maintain aspect ratio */
    margin-right: 10px;
}

.language-name {
    flex: 1;
    text-align: left;
    font-size: 18px;
    margin-right: 10px;
}

.progress-bar {
    flex: 2;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
}

.progress {
    height: 100%;
    background-color: #76c7c0;
    border-radius: 5px 0 0 5px;
}
