html {
    width: 100%;
    overflow-x: clip; /* Prevent horizontal overflow */
    overscroll-behavior: contain;
}

body {
    overscroll-behavior: contain;
    overflow-y: hidden;
}

/** { outline: 1px red solid !important; visibility: visible !important }*/

.navbar {
    background-color: #333;
    color: white;
    padding: 10px 20px;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1000; /* Ensure it's above other content */
}

.navbar-links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
    overflow-x: hidden;
}

.navbar-links li {
    padding: 14px 20px;
    position: relative;
}

.navbar-links li a {
    color: white;
    text-align: center;
    text-decoration: none;
    display: block;
    padding: 10px 15px;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.navbar-links li a:hover {
    background-color: #76c7c0;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.navbar-links li a::before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 50%;
    background-color: #76c7c0;
    transition: width 0.3s ease, left 0.3s ease;
}

.navbar-links li a:hover::before {
    width: 100%;
    left: 0;
}

.navbar-links li a.active::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #76c7c0;
    transition: width 0.3s ease, left 0.3s ease;
}
