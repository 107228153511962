.publications-page {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

.publications-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
