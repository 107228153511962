/* src/components/NotFound.css */
.main-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.not-found-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    z-index: 9999;
}

.not-found-overlay.hidden {
    opacity: 0;
    transform: translateY(-100%);
}

.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: transparent; /* Make sure the background is transparent */
    text-align: center;
    font-family: 'Arial', sans-serif;
}

.not-found-title {
    font-size: 10rem;
    color: #ff6b6b;
    margin: 0;
    animation: bounce 1s infinite;
}

.not-found-message {
    font-size: 1.5rem;
    color: #555;
    margin: 1rem 0;
}

.not-found-link {
    display: inline-block;
    position: relative;
    padding: 10px 15px;
    font-size: 16px;
    color: #007cff;
    text-decoration: none;
    background-color: transparent;
    transition: transform 0.3s ease, background-color 0.3s ease;
    border: none;
    cursor: pointer;
}

.not-found-link::before,
.not-found-link::after {
    content: "<";
    position: absolute;
    font-size: 1.5rem;
    color: #007bff;
    transition: transform 0.3s ease;
}

.not-found-link::before {
    content: "⮝";
    left: -15px;
    transform: translateY(-50%);
}

.not-found-link::after {
    content: "⮝";
    right: -15px;
    transform: translateY(-50%);
}

.not-found-link:hover::after {
    visibility: visible;
    transform: scaleX(1);
}

.not-found-link:hover,
.not-found-link:focus {
    /*background-color: rgba(128, 128, 128, 0.6); !* Grey background with fade-in effect *!*/
    transform: translateY(-2px); /* Slight elevation */
    border-radius: 8px; /* Rounded corners */
}

.not-found-link::before,
.not-found-link::after {
    animation: moveUp 1s infinite alternate;
}

@keyframes moveUp {
    0% {
        transform: translateY(-10%);
    }
    100% {
        transform: translateY(-40%);
    }
}

